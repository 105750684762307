$(document).ready(function () { 
  var count=0;  
  var total=$(".showMore").length
    $(".showMore").slice(0, 3).show();
      if ($(".showMore:hidden").length != 0) {
        $("#loadMore").show();
      }   
      $("#loadMore").on('click', function (e) {
        e.preventDefault();
        console.log( $(".showMore:hidden"));
        $(".showMore:hidden").slice(0, 3).slideDown();
        if ($(".showMore:hidden").length == 0) {
          $("#loadMore").fadeOut('slow');
        }
        count=$(".showMore:visible").length
        var text = "Prikazanih " +count+" od "+total;
       $('#shown').html(text)
      });
      count=$(".showMore:visible").length
      var text = "Prikazanih " +count+" od "+total;
     $('#shown').html(text)
    });
$(document).ready(function () {

    "use strict";

    $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
    //Checks if li has sub (ul) and adds class for toggle icon - just an UI

    $(".menu > ul > li > ul").each(function () {
        var count_children = $(this).children("li").children("ul").length;
        if (count_children < 2) {
            $(this).addClass('normal-sub');
        }
    });

    //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

    $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\"></a>");

    //Adds menu-mobile class (for mobile toggle menu) before the normal menu
    //Mobile menu is hidden if width is more then 959px, but normal menu is displayed
    //Normal menu is hidden if width is below 959px, and jquery adds mobile menu
    //Done this way so it can be used with wordpress without any trouble

    $(".menu > ul > li").hover(
        function (e) {
            if ($(window).width() > 959) {
                $(this).children("ul").addClass('showing');
                e.preventDefault();
            }
        }, function (e) {
            if ($(window).width() > 959) {
                $(this).children("ul").removeClass('showing');
                e.preventDefault();
            }
        }
    );

    $(".menu > ul > li").click(function () {
        if ($(window).width() < 959) {
            $(this).children("ul").fadeToggle(150);
        }
    });

    $(".menu-mobile").click(function (e) {
        $(".menu > ul").toggleClass('show-on-mobile');
        e.preventDefault();
    });

});
